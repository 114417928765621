
.FormSectionsContainer{
    /* border: 2px solid;  */
    /* border-color: grey; */
    /* margin: 5px; */
    /* padding: 5px; */
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    color: var(--app-color);
    /* box-shadow: 2px 2px 2px 2px #d1d1d1; */
}


.Button {
    margin-top: 10px;
    margin-left: 0px;
    height: 40px;
    cursor: pointer;
    background-color: var( --button-color);
    color: white;
    border-radius: 10px;
}

.Button:hover {
    background-color: var( --button-hover-color);
}

.HeaderButton{
    margin-top: 48px;
    margin-left: 5px;
    height: 40px;
    cursor: pointer;
    background-color: var( --button-color);
    color: white;
    border-radius: 10px;
}

.HeaderButton:hover {
    background-color: var( --button-hover-color);
}


.Field { 
    /* border: 1px solid; */
    border-color: grey;
    margin: 5px;
    padding: 5px;
    border-radius: 5px;
}

.FieldEmpty { 
    /* border: 1px solid;
    border-color: grey;
    margin: 5px;
    padding: 5px;
    border-radius: 5px; */
    display: none;
}



.FormFieldContainer{
    /* border: 2px solid; */
    /* border-color: grey; */
    margin-top: 5px;
    /* margin-left: 5px; */
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 3px;
    display: flex;
    flex-direction: row;
    box-shadow: 2px 2px 2px 2px #d1d1d1;
    background-color: #f4f4f4;
}

.HeaderContainer {
    background-color: var(--app-color-headings);
    color: white;
    padding: 5px;
}

.ButtonContainerDrag {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    margin-right: 7px;
}
.fieldContainer{
    position: relative;
}


.SectionsButtonContainer{
    margin-left: 5px;
    margin-top: 10px;
    display: flex;
    flex-direction: row ;
}