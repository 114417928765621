.PrivacyButtonDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    bottom: 30px; 
    left: 50%;
    transform: translateX(-50%);
    margin-top: 400px;
}

.PrivacyButtonDivSmall{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    bottom: 30px; 
    left: 50%;
    transform: translateX(-50%);
    margin-top: 500px;
} 

.PrivacyButton {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    color: #C8CACB;
}

.PrivacyButton:hover {
    color: #d5d8da
}