.Button {
    height: 40px;
    width: 100px;
    margin-right: 5px;
    /* margin-left: 5px; */
    margin-top: 5px;
    cursor: pointer;
    background-color: var( --button-color);
    color: white;
    border-radius: 10px;
    font-size: 12px;
};


.Button:hover {
    background-color: var( --button-hover-color);
}
    
.ButtonOptions {
    height: 30px;
    width: 100px;
    margin-right: 5px;
    /* margin-left: 5px; */
    margin-top: 10px;
    cursor: pointer;
    background-color: var( --button-color);
    color: white;
    border-radius: 10px;
};

.ButtonClose {
    float: right;
};

.DisplayButtonContainer {
    position: fixed;
    display: flex;
    flex-direction: column; /* Ensures the items are arranged in a column */
    align-items: center; /* Optional: Centers the items horizontally */
    justify-content: center; /* Optional: Aligns the items at the start of the container */
    margin-top: 10px; /* Adjusts the top margin */
    gap: 10px; /* Optional: Adds space between the items */
}