/* Custom scrollbar styles */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-button {
  display: none; /* Hide the arrows */
}

/* Firefox */
* {
  scrollbar-width: thin; /* or maybe change this to medium*/
  scrollbar-color: #2b4252 #f1f1f1;
  /* display: none; */
}
