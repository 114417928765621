.Container{
    /* border: 2px solid; */
    /* border-color: grey; */
    margin: 5px;
    margin-top: 30px;
    margin-left: 35%;
    margin-right: 35%;
    padding: 5px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Header {
    display: flex;
}

.CheckBox {
    margin-left: 0px; 
    margin-right: 50px;
    height: 25px;
    width: 25px;
    cursor: pointer;
}

.Input {
    margin-top: 0px;
}


.Button {
    height: 40px;
    width: 350px;
    /* margin-right: 10px;
    margin-left: 5px; */
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    background-color: #2b4252;
    color: white;
    border-radius: 10px;
}

.Button:hover {
    background-color: #82909b;
    /* background-color: #13b5ea; */
    color: white;
  }

.InputClass {
    height: 35px;
    width: 345px;
    border: 1px solid grey;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
}

.Border {
    text-align: center;
    display: flex; 
    /* justify-content: center;  */
    align-items: center; 
    flex-direction: column;
    background-color: #ffffff;
    margin-top: 60px;
    padding-bottom: 30px;
    padding-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 10px;
    border: 2px solid #ccc;
}


.Logo {
    margin-top: 0px;
    align-items: left;
    height: 150px;
    width: 150px;
}

.LogoDiv {
    margin-top: -10px;
}

hr {
    background-color: rgb(25, 24, 24);
    border-style: none;
    border-top-style: dotted;
    height: 0px;
    width: 100%;
    margin-bottom: 20px;
  }

.Heading {
    margin-top: 10px;
    color: #8e8a8af7;
    margin-bottom: 20px;
}


/* .PrivacyButtonDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
} */

.PrivacyButtonDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: 20px;
    /* margin-bottom: 50px; */
    /* bottom: 50px; */
    left: 50%;
    transform: translateX(-50%);
}


.PrivacyButton {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    color: #C8CACB
}

.PrivacyButton:hover {
    color: #d5d8da
}