.Button{
    height: 40px;
    width: 120px;
    margin-top: 10px;
    margin-right: 10px;
    cursor: pointer;
    background-color: var( --button-color);
    color: white;
    border-radius: 10px;
    background-color: var( --button-color);
    color: white;
    border-radius: 10px;
    font-size: 12px;
}


.Button:hover {
background-color: var( --button-hover-color);
}


.ButtonContainer {
    display: flex;
    flex-direction: column ;
}

.ButtonContainerDrag {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    margin-right: 50px;
}
.fieldContainer{
    position: relative;
}

.FieldsButtonContainer{
    margin-left: 2px;
    margin-top: 10px;
    display: flex;
    flex-direction: row ;
}