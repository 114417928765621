.FormFieldContainer{
    border: 2px solid;
    border-color: grey;
    margin: 5px;
    padding: 5px;
    display: flex;
    flex-direction: column;
}


.ValueContainer{
    border: 2px solid;
    border-color: grey;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 5px;
    padding: 5px;
}

.Value {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    margin-left: 5px;
    /* padding: 5px; */
    /* border: 2px solid;
    border-color: grey; */
}

.Header {
    display: flex;
}

.CheckBox {
    margin-left: 0px; 
    margin-right: 50px;
    height: 25px;
    width: 25px;
    cursor: pointer;
}

.Input {
    margin-top: 0px;
}


.Button {
    height: 40px;
    width: 100px;
    margin-right: 10px;
    margin-left: 0px;
    margin-top: 5px;
    cursor: pointer;
}

.ValueButton {
    height: 30px;
    width: 100px;
    margin-right: 10px;
    margin-left: 5px;
    margin-top: 5px;
    cursor: pointer;
}


.FlexDirection {
    display: flex;
    flex-direction: column;
}

.ButtonDirection{
    display: flex;
    flex-direction: row;
}

.Field { 
    border: 1px solid;
    border-color: grey;
    margin: 5px;
    padding: 5px;
    border-radius: 5px;
}

.Select {
    font-size: 14px;
    width: 150px;

}

.PopUp {
    height: 100px;
    width: 200px;
    position: cen;
    border: 3px solud;
    border-color:red;
}

/* We can make the filter values horizontally aigned as well, Discuss with Jaye */

.List {
    /* display: flex;
    flex-direction: row; */
    margin: 0px;
    padding-left: 20px;
    /* padding-right: 0px; */
    /* width: 10px; */
}

.ListButton {
    height: 20px;
    width: 20px;
    margin-left: 5px;
    padding: 0px;
    border-radius: 10px;
    font-size: 10px;
    font-style: bold;
}
