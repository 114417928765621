.FormSelectionContainer{
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center; 
}

.Button {
    width: 500px;
    height: 100px;
    margin-top: 50px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 18px;
}

.Button:hover {
    background-color: grey;
    color: white;
}
