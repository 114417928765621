:root {
  --app-color: #2b4252;
  --app-color-light: #2b4252;
  /* --app-color-secondary: #88b92c; */
  --app-color-secondary: #2b4252;
  --app-color-headings: #b4b9bd ;
  /* --app-color-headings: #88b92c; */
  --text-color: #2b4252;
  --button-color: #2b4252;
  --button-color-new: #131b21;
  --button-color-red: red;
  --button-hover-color: #526a7c;
  --button-hover-color-red: #F74F4F;
  --field-background-color-1:#f8f8f8;
  --field-background-color-2:#eaeaea;
  --section-background-color:#fafafa;
  --icon-button-color-green: #a7ca50;
  --icon-button-color-grey: #767675;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tabs {
  overflow: hidden;
  background: #f1f1f1;
  display: flex;
  padding: 0;
  margin: 0;
}

.tab {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

.tab:hover {
  background-color: #ddd;
}

.tab.active {
  background-color: #ccc;
}

.main-content {
  margin-top: 50px; /* Adjust based on the actual height of your navigation */
}


.navigation {
  position: sticky;
  top: 0;
  z-index: 1000; /* Make sure it stays on top of other content */
  display: flex;  /* Aligns the tabs horizontally */
  overflow-x: auto;  /* Allows horizontal scrolling on smaller screens */
  white-space: nowrap;  /* Keeps tabs in a single line */
  background-color: #f1f1f1;  /* Light grey background */
  padding: 10px 0;  /* Padding around the tabs */
  border-bottom: 2px solid #ccc;  /* Adds a border underneath the tabs */
}

.tab-button {
  display: inline-block;
  padding: 10px 20px;  /* Padding inside each tab */
  cursor: pointer;  /* Pointer cursor on hover */
  border: none;  /* No border for the button */
  background-color: inherit;  /* Takes background color from parent */
  outline: none;  /* Removes focus outline */
  transition: background-color 0.3s;  /* Smooth transition for background color */
  font-size: 16px;  /* Set font size */
  border-radius: 5px 5px 0 0;  /* Rounded corners on the top */
}

.tab-button:hover, .tab-button.active {
  background-color: #ddd;  /* Changes background on hover and for active tab */
}