/* Modal styles */
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  color: var(--app-color);
  /* max-width: 50%; */
}

.modal-content {
  background-color: #fefefe;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}



.Button {
  margin-top: 10px;
  margin-left: 0px;
  height: 40px;
  width: 80px;
  cursor: pointer;
  background-color: var( --button-color);
  color: white;
  border-radius: 10px;
}

.Button:hover {
  background-color: var( --button-hover-color);
}