.Button {
    height: 40px;
    width: 100px;
    margin-right: 10px;
    margin-left: 5px;
    margin-top: 5px;
    cursor: pointer;
};

.ButtonClose {
    float: right;
};

.DisplayButtonContainer{
    display: flex;
    flex-direction: column;
}