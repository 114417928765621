.SectionFormContainer {
    /* border: 2px solid; */
    /* border-color: grey; */
    margin-top: 5px;
    /* margin: 5px; */
    padding: 5px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    color: var( --app-color);
    /* background-color: var(--section-background-color); */
    box-shadow: 1px 1px 1px 1px #d1d1d1;
}

.Button {
    margin-top: 10px;
    height: 40px;
    width: 120px;
    cursor: pointer;
    background-color: var( --button-color);
    color: white;
    border-radius: 10px;
    font-size: 12px;
}

.Button:hover {
    background-color: var( --button-hover-color);
}

.SectionContainer {
    display: flex;
    flex-direction: column;
}

.SectionButtonContainer {
    display: flex;
    flex-direction: row;
}

.Label{
    font-weight: bold;
    font-size: 18px;
}

.HeaderContainer {
    background-color: var(--app-color-headings);
    color: white;
    padding: 5px;
}