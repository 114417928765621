.FormFieldContainerTop1 {
    /* border: 2px solid; */
    border-color: grey;
    margin-top: 5px;
    padding: 3px;
    overflow: auto;
    color: var( --app-color);
    /* margin-left: 1.2%;
    margin-right: 2%; */
    background-color: var(--field-background-color-1);
}

.FormFieldContainerTop2 {
    /* border: 2px solid; */
    border-color: grey;
    margin-top: 5px;
    padding: 3px;
    overflow: auto;
    color: var( --app-color);
    /* margin-left: 1.2%;
    margin-right: 2%; */
    background-color: var(--field-background-color-2);
}

.FormFieldContainer {
    /* border: 2px solid;
    border-color: grey; */
    margin-top: 5px;
    padding: 3px;
    display: flex;
    flex-direction: row;
    overflow: auto;
    color: var( --app-color);
}

.FormFieldContainerVertical {
    /* border: 2px solid;
    border-color: grey; */
    margin-top: 5px;
    padding: 3px;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.Header {
    display: flex;
}

.CheckBox {
    margin-left: 0px; 
    margin-right: 50px;
    height: 25px;
    width: 25px;
    cursor: pointer;
}

.Input {
    margin-top: 0px;
}


.Button {
    height: 30px;
    width: 100px;
    margin-right: 10px;
    margin-left: 0px;
    margin-top: 10px;
    cursor: pointer;
    background-color: var( --button-color);
    color: white;
    border-radius: 10px;
}

.Button:hover {
    background-color: var( --button-hover-color);
}


.FlexDirection {
    display: flex;
    flex-direction: column;
}

.ButtonDirection{
    display: flex;
    flex-direction: row;
}

.Field { 
    /* border: 1px solid; */
    /* border-color: grey; */
    /* margin: 5px;
    padding: 5px;
    border-radius: 5px; */
    margin: 5px;
}

.Select {
    font-size: 14px;
    width: 150px;
}

.HeaderContainer {
    background-color: var(--app-color-headings);
    color: white;
    padding: 5px;
}

.ClassesNone {
    display: none;

}
.checkClass{
    display: flex;
    flex-direction: row;

}